class Bar {
  elements: {
    volume?: null;
    played?: null;
    loaded?: null;
    maxDuration?: null;
  };
  constructor(template) {
    this.elements = {};
    this.elements.volume = template.volumeBar;
    this.elements.played = template.playedBar;
    this.elements.loaded = template.loadedBar;
    this.elements.maxDuration = template.maxDurationBar;
  }

  /**
   * Update progress
   *
   * @param {String} type - Point out which bar it is
   * @param {Number} percentage
   * @param {String} direction - Point out the direction of this bar, Should be height or width
   */
  set(type, percentage, direction) {
    // console.log(this.elements, 'this.elements');
    percentage = isNaN(percentage) ? 0 : percentage;
    // console.log(type, percentage, direction, 'direction')
    percentage = Math.max(percentage, 0);
    percentage = Math.min(percentage, 1);
    // console.log(percentage.toFixed(2), 'fixed');
    this.elements[type].style[direction] = percentage.toFixed(2) * 100 + "%";
  }

  get(type) {
    return parseFloat(this.elements[type].style.width) / 100;
  }
}

export default Bar;

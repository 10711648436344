<div class="CloudPlayback-controller-mask"></div>
<div class="CloudPlayback-controller">
    <div class="CloudPlayback-icons CloudPlayback-icons-left">
        <button class="CloudPlayback-icon CloudPlayback-play-icon">
            <span class="CloudPlayback-icon-content">{{@ icons.play }}</span>
        </button>
        <div class="CloudPlayback-volume">
            <button class="CloudPlayback-icon CloudPlayback-volume-icon">
                <span class="CloudPlayback-icon-content">{{@ icons.volumeDown }}</span>
            </button>
            <div class="CloudPlayback-volume-bar-wrap" data-balloon-pos="up">
                <div class="CloudPlayback-volume-bar">
                    <div class="CloudPlayback-volume-bar-inner" style="background: {{ options.theme }};">
                        <span class="CloudPlayback-thumb" style="background: {{ options.theme }}"></span>
                    </div>
                </div>
            </div>
        </div>
        <span class="CloudPlayback-time">
            <span class="CloudPlayback-ptime">0:00</span> /
            <span class="CloudPlayback-dtime">0:00</span>
        </span>
        {{ if options.live }}
        <span class="CloudPlayback-live-badge"><span class="CloudPlayback-live-dot" style="background: {{ options.theme }};"></span>{{ tran('Live') }}</span>
        {{ /if }}
    </div>
    <div class="CloudPlayback-icons CloudPlayback-icons-right">
        {{ if options.isAudioChange }}
        <div class="CloudPlayback-speed">
            {{ if options.playAudio }}
                <button class="CloudPlayback-icon CloudPlayback-playAudio-icon">音</button>
            {{else}}
                <button class="CloudPlayback-icon CloudPlayback-playAudio-icon">视</button>    
            {{ /if }}
        </div>
        {{ /if }}

        {{ if options.video.multiStreamSource }}
        <div class="CloudPlayback-quality multi-stream-wrapper">
            <button class="CloudPlayback-icon CloudPlayback-multistream-icon">{{ options.video.multiStreamSource[options.video.multiStreamIndex].name }}</button>
            <div class="CloudPlayback-multistream-mask">
                <div class="CloudPlayback-multistream-list">
                {{ each options.video.multiStreamSource }}
                    <div class="CloudPlayback-quality-item" data-index="{{ $index }}">{{ $value.name }}</div>
                {{ /each }}
                </div>
            </div>
        </div>
        {{ /if }}
        {{ if options.video.quality }}
        <div class="CloudPlayback-quality quality-wrapper">
            <button class="CloudPlayback-icon CloudPlayback-quality-icon">{{ tran(options.video.quality[options.video.defaultQuality].name) }}</button>
            <div class="CloudPlayback-quality-mask">
                <div class="CloudPlayback-quality-list">
                {{ each options.video.quality }}
                    <div class="CloudPlayback-quality-item" data-index="{{ $index }}">{{ tran($value.name) }}</div>
                {{ /each }}
                </div>
            </div>
        </div>
        {{ /if }}
        {{ if options.playbackSpeed }}
        <div class="CloudPlayback-speed">
            <button class="CloudPlayback-icon CloudPlayback-speed-icon">{{ options.playbackSpeed[2] }} x</button>
            <div class="CloudPlayback-speed-mask">
                <div class="CloudPlayback-speed-list">
                {{ each options.playbackSpeed }}
                    <div class="CloudPlayback-speed-item" data-index="{{ $index }}">{{ $value }}</div>
                {{ /each }}
                </div>
            </div>
        </div>
        {{ /if }}
        {{ if options.isPip }}
        <div class="CloudPlayback-pip">
            <button class="CloudPlayback-icon CloudPlayback-pip-icon">
                <span class="CloudPlayback-icon-content pip-web-icon">{{@ icons.pipOpen }}</span>
            </button>
        </div>
        {{ /if }}
        <div class="CloudPlayback-full"> 
            <button class="CloudPlayback-icon CloudPlayback-full-icon" data-balloon="{{ tran('Full screen') }}" data-balloon-pos="up">
                <span class="CloudPlayback-icon-content full-web-icon">{{@ icons.fullScreenOpen }}</span>
            </button>
        </div>
    </div>
    <div class="CloudPlayback-bar-wrap">
        <div class="CloudPlayback-bar-time hidden">00:00</div>
        <div class="CloudPlayback-bar-preview"></div>
        <div class="CloudPlayback-bar">
            <div class="CloudPlayback-loaded" style="width: 0;"></div>
            <div class="CloudPlayback-max-duration" style="width: 0;"></div>
            <div class="CloudPlayback-played" style="width: 0; background: {{ options.theme }}">
                <span class="CloudPlayback-thumb CloudPlayback-split-ball" style="border: 2px solid {{ options.theme }}"></span>
            </div>
        </div>
    </div>
</div>